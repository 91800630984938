.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  height: 5rem;
  z-index: 1;
  font-family: var(--font-poppins);
  color: var(--color-title);
  font-size: 1.25rem;
  padding: 1.5rem 3.5rem;
  transition: 0.4s ease;
  opacity: 0;
  animation: fadeInUp 1s forwards;
}

.scrolled {
  filter: drop-shadow(0 4px 5px #00000060);
  background: #152232;
}

.logo {
  font-size: 30px;
  text-decoration: none;
  font-weight: 700;
  transition: all 0.3s ease;
  cursor: pointer;
}

.logo:hover {
  color: var(--color-primary);
}

.nav-link {
  display: inline-block;
  cursor: pointer;
}

/* Desktop nav links */
.nav-links {
  display: flex;
  justify-content: space-between;
  width: 25rem;
}

.nav-links a {
  transition: all 0.3s ease;
}

.nav-links a:hover {
  color: var(--color-primary);
}

.active {
  border-bottom: 2.5px solid white;
  padding-bottom: 3px;
}

/* Mobile nav links */
.mobile-nav-links {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  z-index: 1;
  background: #152232;
}

/* Show mobile nav-links when menu is open */
.mobile-nav-links.open {
  display: flex;
}

/* Burger menu icon for mobile */
.burger-menu {
  display: none;
  font-size: 2rem;
  cursor: pointer;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .nav-link {
    padding: 0.5rem 1rem;
    text-align: center;
  }

  .burger-menu {
    display: block; /* Display burger menu on mobile */
  }
}

.footer-section {
  background: #11161d none repeat scroll 0 0;
  color: var(--color-title);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -4px 3px #00000060;
  height: auto;
  padding: 46px;
}

.footer-icons-container {
  display: flex;
  gap: 1.3rem;
  margin: 16px;
}

.footer-section p {
  color: #999;
}

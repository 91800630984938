@import url("https://fonts.googleapis.com/css2?family=Poppins");

* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

:root {
  --font-poppins: "Poppins", sans-serif;
  --font-raleway: "Raleway", sans-serif;
  --color-primary: #1cb4ff;
  --color-title: white;
  --color-body-dark: #15191f;
  --color-body-light: #3c4653;
  --color-body: linear-gradient(to bottom, #152232, var(--color-body-dark));
}

body {
  font-family: var(--font-poppins);
  background: var(--color-body);
  background-attachment: fixed;
  max-width: 100%;
  overflow-x: hidden;
}

/*
::-webkit-scrollbar {
  width: 10px;
  background: var(--color-body);
  display: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background: var(--color-primary);
}
*/

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier();
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier();
  }
}

@media (max-width: 900px) {
  /*changes reflects on screen with a width of 720px and below*/
  .navbar {
    padding: 1.5rem 1rem !important;
  }

  .nav-links {
    /*flex-direction: column;*/
    display: none !important;
    padding: 0;
  }

  .nav-links li {
    margin: 8px;
  }

  .burger-menu {
    display: block !important;
  }

  .landing-section > h1 {
    font-size: 3.5rem !important;
  }

  .landing-section p {
    font-size: 1.5rem !important;
  }

  /*About Section*/
  .about-container {
    flex-direction: column !important;
  }

  /*Project Section*/
  .project-section {
    padding: 6rem 3rem !important;
  }

  .project {
    flex-direction: column;
  }

  .project-image {
    object-fit: cover;
  }
}

.landing-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: var(--color-title);
}

.landing-section > h1 {
  font-size: 70px;
  opacity: 0;
  animation: fadeInUp 1s forwards;
  animation-delay: 0.3s;
}

.landing-section span {
  color: var(--color-primary);
}

.landing-section > p {
  font-size: 30px;
  opacity: 0;
  animation: fadeInUp 1s forwards;
  animation-delay: 0.6s;
}

.icons-container {
  display: flex;
  gap: 1.3rem;
  margin: 16px;
  opacity: 0;
  animation: fadeInUp 1s forwards;
  animation-delay: 0.9s;
}

.icon-container {
  color: white;
  transition: all 0.3s ease;
}

.icon-container:hover {
  color: var(--color-primary);
}

.arrow-container {
  opacity: 0;
  animation: fadeInUp 1s forwards, bounce 1s infinite;
  animation-delay: 1.6s;
  position: absolute;
  bottom: 30px;
}

/* For Phones */
@media only screen and (max-width: 550px) {
  .landing-section > h1 {
    font-size: 2.3rem !important;
  }

  .landing-section p {
    font-size: 1.3rem !important;
  }

  .icon {
    height: 35px;
    width: 35px;
  }

  .arrow-icon {
    display: none;
  }
}

.project-section {
  height: auto;
  color: var(--color-title);
  padding: 6rem;
  text-align: center;
}

.project-section > h1 {
  font-size: 4rem;
}

.project-section span {
  color: var(--color-primary);
}

.projects-list {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-items: center;
  margin-top: 3rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.project {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  max-width: 1060px;
  margin: 48px 0;
  text-align: center;
  gap: 7%;
  border-radius: 10px;
  padding: 20px;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  text-decoration: none;
  color: inherit;
}

.project:hover {
  background-color: #20f7da10;
}

.project-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.project-title h1,
.icon-link {
  transition: color 0.3s ease-in-out;
  color: var(--color-title);
}

.project:hover .project-title h1,
.project:hover .icon-link {
  color: var(--color-primary);
}

.project-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-image img {
  height: 280px;
  padding: 3px;
  border-radius: 16px;
  box-shadow: 0 3px 10px rgba(220, 220, 220, 0.5);
}

.project-info {
  display: flex;
  flex-direction: column;
}

.project-info p {
  margin: 20px 0;
  text-align: left;
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.tech-item {
  display: flex;
  align-items: center;
  border-radius: 20px;
  background-color: rgba(45, 212, 191, 0.1);
  padding: 4px 12px;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

/* For Phones */
@media (max-width: 500px) {
  .project-section {
    margin: 3rem 0;
    padding: 0 !important;
    text-align: center;
  }

  .project-section > h1 {
    font-size: 3rem !important;
  }

  .projects-list {
    grid-template-columns: 1fr;
  }

  .project {
    grid-template-columns: 1fr;
    padding: 20px 0;
  }

  .project:hover {
    background-color: transparent;
    box-shadow: none;
  }

  .project-image img {
    height: 210px;
  }

  .project-info {
    text-align: center;
  }

  .project-title {
    justify-content: center;
    margin-top: 20px;
  }

  .project-info p {
    font-size: 0.8rem;
    padding: 1rem;
    margin: 0;
    text-align: center;
  }

  .tech-stack {
    justify-content: center;
  }

  .tech-item {
    padding: 2px 8px;
    line-height: 1rem;
  }
}

/* For phones */
@media (max-width: 500px) {
  .project-section {
    margin: 3rem 0;
    padding: 0 !important;
    text-align: center;
  }

  .project-section > h1 {
    font-size: 3rem !important;
  }

  .projects-list {
    grid-template-columns: 1fr;
  }

  .project {
    grid-template-columns: 1fr;
    padding: 20px 0;
  }

  .project:hover {
    background-color: transparent;
    box-shadow: none;
  }

  .project-image img {
    height: 210px;
  }

  .project-info {
    text-align: center;
  }

  .project-title {
    justify-content: center;
    margin-top: 20px;
  }

  .project-info p {
    font-size: 0.8rem;
    padding: 1rem;
    margin: 0;
    text-align: center;
  }

  .tech-stack {
    justify-content: center;
  }

  .tech-item {
    padding: 2px 8px;
    line-height: 1rem;
  }
}

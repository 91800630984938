.about-section {
  height: auto;
  color: var(--color-title);
  padding: 6rem 8rem;
}

.about-section > h1 {
  font-size: 4rem;
  text-align: center;
}

.about-section span {
  color: var(--color-primary);
}

.about-container {
  display: flex;
  gap: 10%;
  margin: 20px auto;
}

.about-bio {
  font-size: 18px;
}

.about-text {
  margin-bottom: 20px;
  text-align: justify;
}

.headshot {
  width: 350px;
  border-radius: 15px;
}

.skills-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  padding: 3.5rem 0;
}

.skill-summary {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: 0 6rem;
}

.skill {
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease-in-out;
  margin: 0.5rem;
}

.skill-title {
  text-transform: uppercase;
  text-align: center;
}

.skill-img {
  width: 200px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  transition: all 0.4s ease-in-out;
}

.skill-img:hover {
  transform: translateY(-8px);
}

.resume-button {
  border: 1px solid #1cb4ff;
  background-color: transparent;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: var(--font-poppins);
}

.resume-button:hover {
  background-color: #1cb4ff;
}

/* For Phones */
@media only screen and (max-width: 550px) {
  .about-section {
    padding: 0 !important;
    text-align: center;
    margin-bottom: 2rem !important;
  }

  .about-section > h1 {
    font-size: 3rem;
  }

  .about-container {
    flex-direction: column-reverse !important;
    align-items: center;
    justify-content: center;
  }

  .about-text {
    font-size: small;
    padding: 10px;
    text-align: center;
  }

  .headshot {
    width: 50% !important;
    margin-bottom: 3rem;
  }

  .skill-summary {
    padding: 0;
  }

  .skill-img {
    width: 100px;
  }
}

.contact-section {
  height: auto;
  text-align: center;
  color: var(--color-title);
  padding: 6rem 6rem;
}

.contact-section > h1 {
  font-size: 4rem;
}

.contact-section span {
  color: var(--color-primary);
}

.contact-form-container {
  max-width: 40.75rem;
  margin: 0 auto;
  padding: 0.938rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form input,
textarea {
  font-family: var(--font-poppins);
  border-radius: 20px;
  padding: 0.8rem 1.1rem;
  margin: 8px;
  font-size: 16px;
  width: 100%;
  outline: none;
  resize: none;
  border: none;
  background-color: #2a2e35;
  color: white;
}

.send-button {
  border-radius: 20px;
  width: 100%;
  color: var(--color-title);
  transition: all 0.5s;
  cursor: pointer;
  font-family: var(--font-poppins);
  margin: 10px;
  padding: 10px 20px;
  border: 1px solid #1cb4ff;
  background-color: transparent;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.send-button:hover {
  background-color: #1cb4ff;
}

/* For Phones */
@media (max-width: 500px) {
  .contact-section {
    text-align: center;
    margin: 5rem 0 !important;
    padding: 0 !important;
  }

  .contact-section > h1 {
    font-size: 3rem;
  }

  .contact-form {
    padding: 0 !important;
  }

  .contact-form input,
  textarea {
    font-family: var(--font-poppins);
    border-radius: 4px;
    padding: 12px;
    margin: 8px;
    font-size: 16px;
    width: 100%;
    outline: none;
    resize: none;
  }
}
